/* You can add global styles to this file, and also import other style files */

/*html, body { height: 100%; }*/
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~ngx-toastr/toastr';

/* Prime NG  */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";


html, body { height: 100%; }

/*styles for email select auto complete */
.p-overlay
{
    top:32px;
}
.p-autocomplete-multiple-container
{
    padding-left: 10px;
    margin-top: 5px;
    margin-bottom: 0 !important;
}
.p-autocomplete-token-label
{
    margin-right: 3px;
}
.p-autocomplete-token
{
    margin-right: 10px;
    background-color: #d6d9dc;
    padding: 2px 7px;
    border-radius: 3px;
    margin-bottom: 5px;
}
.p-autocomplete-panel
{
    background-color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    border:1px solid #d6d9dc;
    margin-top:7px;
}

.p-autocomplete-items
{
    padding-left: 5px;
}
.p-autocomplete-item
{
    padding-top: 5px;
    padding-bottom: 5px;
}
.p-autocomplete-input
{
    border: none;
    padding-top: 6px;
    padding-left: 8px;
    width: 100%;
}
.p-inputwrapper
{
    display: inline;
}

quill-editor
{
    width:100%;
}
.ql-container
{
    position: relative;
}
.ql-tooltip
{
    left: 515px !important;
    top: -11.2px !important;
    position: absolute;
}

.search-autocomplete.p-autocomplete-input
{
   min-height: 32px;
}

.cust-tooltip {
    white-space: normal !important; /* Allow text wrapping */
    max-width: 500px !important;      /* Remove any max-width restriction */
    pointer-events: auto !important; /* Allow hover events on the tooltip */
    padding: 5px !important;         /* Optional: adjust padding as needed */     
  }

  .cust-tooltip img {
    max-width: 100% !important;
    max-height: 200px !important; 
    display: block;
    object-fit: contain; 
  }

